import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slider1 from "../assets/slider1.png";
import { FaArrowRight } from "react-icons/fa";
import Loading from '../assets/loading.gif'
import { useCompany } from '../contexts/CompanyContext';

const SimpleSlider = () => {
  const { companyId, companyData, isLoadingCompanyData } = useCompany();

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 3000,
    // pauseOnHover: true
  };

  if (isLoadingCompanyData) {
    return <div className="text-center my-5 d-flex justify-content-center align-items-center" style={{ minHeight: '400px' }}>
      <img src={Loading} className="img-fluid" style={{ objectFit: 'cover', width: '300px', height: '300px' }} />
    </div>;
  }

  if (!companyData) {
    return <div>No company data available.</div>;
  }

  return (
    <div className="carousel-contain mt-6">
      <Slider {...settings}>
        {companyData.images.map(img => (
            <div>
              <img src={img.file_url} className="car-pic" alt="Slider Image 1" />
            </div>
        ))}
      </Slider>
    </div>
  );
};

export default SimpleSlider;
