import React, { useState, useEffect, useCallback } from 'react';
import { FaShoppingBag } from "react-icons/fa";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import logo from "../assets/cara.png";
import "../styles/Navbar.css";
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../assets/loading.gif'
import { useCompany } from '../contexts/CompanyContext';
import Api from "../api/api";
import apiUtil from '../api/apiUtil';

const MobileNav = () => {
  const navigate = useNavigate();
  const { companyId, companyData, isLoadingCompanyData } = useCompany();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    callReadApi();
  }, []);

  const callReadApi = useCallback(() => {

    let param = {
      company_id: companyId,
    };

    const getData = Api.read('top-tab', param);

    Promise.all([getData]).then((results) => {
      apiUtil.parseResult(results[0], (data) => {
        const dataArr = data.data ? data.data : [];

        setTabs(dataArr.data);

      }, (error, type) => {
        setTabs([]);

      });
    });
  }, []);

  // Toggle the menu visibility
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  if (isLoadingCompanyData) {
    return <div className="text-center my-5 d-flex justify-content-center align-items-center" style={{ minHeight: '400px' }}>
      <img src={Loading} className="img-fluid" style={{ objectFit: 'cover', width: '300px', height: '300px' }} />
    </div>;
  }

  return (
    <div className='mobNavMainPrant'>
      <div className="navbar">
        <div className='d-flex px-4 justify-content-between align-items-center w-100'>
          {/* Logo */}
          <Link to="/">
            <img src={companyData?.logo || logo} className="w-32" alt="Logo" />
          </Link>

          {/* Hamburger Icon to toggle the menu */}
          <button className="navbar-toggler" type="button" onClick={toggleMenu}>
            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} className="text-xl" />
          </button>
        </div>
      </div>

      {/* Collapsible Menu */}
      <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>
        <ul className="menuNav flex flex-col p-4">
          <li className="dropdown nav-link">
            <Link to="/catalog" onClick={toggleMenu}>PRODUCTS</Link>
          </li>
          {/* <li className="dropdown nav-link">
            <Link to="/customize-nfc" onClick={toggleMenu}>CUSTOMIZE NFC</Link>
          </li> */}
          {/* <li className="dropdown nav-link">
                        <Link to="/search-order" onClick={toggleMenu}>View Order</Link>
                    </li> */}

          {tabs.map(tab => (
            <li className="dropdown nav-link"
              onClick={() => navigate(`/pages/${tab.name.toLowerCase().replace(/\s+/g, '-')}`, { state: { id: tab.id } })}>
              {tab.name}
            </li>
          ))}

          <li className="dropdown nav-link">
            <Link to="/pages/contact-us" onClick={toggleMenu}>Contact Us</Link>
          </li>

          <hr />
          <li className="dropdown nav-link">
            {/* Cart Icon */}
            <Link to="/cart">
              <FaShoppingBag className='text-xl' />
            </Link>
          </li>
          <hr />
        </ul>
      </div>
    </div>
  );
};

export default MobileNav;
