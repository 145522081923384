import React, { useState, useEffect, useRef, useCallback, useContext } from 'react'
import { store } from "../productsStore/Store";
import OurBestSellers from './OurBestSellers';
import Api from "../api/api"
import apiUtil from '../api/apiUtil';
import { Link } from 'react-router-dom';
import { FaShoppingBag } from "react-icons/fa";
import { useCompany } from '../contexts/CompanyContext';
import Loading from '../assets/loading.gif'

const Products = () => {
    const { companyId, companyData, isLoadingCompanyData } = useCompany();
    // const { companyId, companyData } = useCompany();
    // const [data, setData] = useState([]);
    // const [discount, setDiscount] = useState(null);
    // const [isLoading, setIsLoading] = useState(false)

    // useEffect(() => {
    //     callReadApi();
    // }, [companyId])

    // const callReadApi = useCallback(() => {

    //     let param = {
    //         company_id: companyId
    //     };

    //     setIsLoading(true);
    //     const getData = Api.read('products', param);

    //     Promise.all([getData]).then((results) => {
    //         apiUtil.parseResult(results[0], (data) => {
    //             const dataArr = (data.data && data.data.length > 0) ? data.data : []
    //             const discountData = data.discount || null;

    //             const transformedData = dataArr.map((product) => {
    //                 const discountedPrice = calculateDiscountPrice(product.price, discountData);
    //                 return {
    //                     id: product.id,
    //                     name: product.name,
    //                     price: discountedPrice, // Using discounted price instead of original price
    //                     originalPrice: product.price,
    //                     type: 'ourBestSellers',
    //                     primaryImage: product.image_url,
    //                     hoverImg: product?.images?.length > 0 ? product.images[0].file_url : product.image_url,
    //                     gallery_images: product.images,
    //                     description: product.description,
    //                     quantity: product.quantity,
    //                     stripeDiscountId: discountData ? discountData.stripe_coupon_id : null, // For Discount
    //                 };
    //             });

    //             setData(transformedData);
    //             setDiscount(discountData);
    //             setIsLoading(false);

    //             console.log('data ds', dataArr, transformedData)
    //         }, (error, type) => {
    //             setData([]);
    //             setIsLoading(false);
    //         });
    //     });
    // }, [])

    // const calculateDiscountPrice = (price, discountData) => {
    //     if (!discountData) return price;

    //     const { discount_type, amount } = discountData;

    //     // Calculate based on discount type (e.g., 'percent' or 'fixed')
    //     if (discount_type === 'percent') {
    //         return price - (price * amount) / 100;
    //     } else if (discount_type === 'fixed') {
    //         return price - amount;
    //     }

    //     return price; // If no discount type matches, return the original price
    // };
    if (isLoadingCompanyData) {
      return <div className="text-center my-5 d-flex justify-content-center align-items-center" style={{ minHeight: '400px' }}>
        <img src={Loading} className="img-fluid" style={{ objectFit: 'cover', width: '300px', height: '300px' }} />
      </div>;
    }

    return (
        // <div className='container-fluid'>
        //     <div className='row w-100 mt-4 p-0 m-0'>
        //         {data && data
        //         .filter(item => item.type === "ourBestSellers") 
        //         .sort((a, b) => b.id - a.id)                
        //         .slice(0, 4).map(((item) => {
        //                 return (

        //                     <OurBestSellers
        //                         key={item.id}
        //                         id={item.id}
        //                         title={item.name}
        //                         price={item.price}
        //                         originalPrice={item.originalPrice}
        //                         stripeDiscountId={item.stripeDiscountId}
        //                         image={item.primaryImage}
        //                     />
        //                 )
        //         }))}
        //     </div>
        //     <Link to={`/catalog`} className="px-4 col-12 col-lg-12 d-flex justify-content-end align-items-center">  
        //         <a className='fs-6 d-flex align-items-center' style={{color: companyData && companyData.primary_color}}><FaShoppingBag /> &nbsp; &nbsp; Shop all items</a>
        //     </Link> 
        // </div>

        <div className='container-fluid grid grid-cols-1 md:grid-cols-2 gap-3'>

          <a href={companyData.solution_link_1??'#'}>
            <img className="w-full" src={companyData.solution_image_1} alt='Solution One'/>
          </a>

          <a href={companyData.solution_link_2??'#'}>
            <img className="w-full" src={companyData.solution_image_2} alt='Solution two'/>
          </a>
        </div>
    )
}

export default Products