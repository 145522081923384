import React, { useState, useEffect, useCallback } from 'react';
import "../styles/Navbar.css";
import logo from "../assets/cara.png";
import BestSellers from './BestSellers';
import MoreMenu from './MoreMenu';
import GiftSets from './GiftSets';
import Body from './Body';
import { FaShoppingBag } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import Api from "../api/api";
import apiUtil from '../api/apiUtil';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { useCompany } from '../contexts/CompanyContext';

const NavBar = () => {
  const navigate = useNavigate();
  const { companyId, companyData, isLoadingCompanyData } = useCompany();
  // console.log(companyData.logo)
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);


  const [isLoading, setIsLoading] = useState(false);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    callReadApi();
  }, []);

  const callReadApi = useCallback(() => {

    let param = {
      company_id: companyId,
    };


    setIsLoading(true);
    const getData = Api.read('top-tab', param);

    Promise.all([getData]).then((results) => {
      apiUtil.parseResult(results[0], (data) => {
        const dataArr = data.data ? data.data : [];

        setTabs(dataArr.data);
        setIsLoading(false);
      }, (error, type) => {
        setTabs([]);
        setIsLoading(false);
      });
    });
  }, []);

  const handleNavigation = (name, id) => {
    navigate(`/pages/${name.toLowerCase()}`, { state: { id } });
  };


  const showHandler = () => {
    setShow(true)
    setShow2(false)
    setShow3(false)
    setShow4(false)

  }

  const showHandler2 = () => {
    setShow2(true)
    setShow(false)
    setShow3(false)
    setShow4(false)

  }

  const showHandler3 = () => {
    navigate("/search-order");
  }

  const showHandler4 = () => {
    setShow4(true)
    setShow(false)
    setShow2(false)
    setShow3(false)
  }


  const dontShowHandler = () => {
    setShow(false)
    setShow2(false)
    setShow3(false)
    setShow4(false)


  }

  if (isLoading || !companyData || isLoadingCompanyData) {
    return <div>Loading...</div>;
  }

  return (
    <div className='mb-4'>
      <header className="banner" role="banner">

        <nav className="navbar d-flex justify-content-between align-items-center" role="navigation" aria-label="menu">

          <Link to="/">
            <img src={companyData.logo} className=" ml-32" style={{ objectFit: 'contain', height: '50px' }} />
          </Link>

          <ul className="menuNav d-flex align-items-center">
            <li className="dropdown nav-link nav-link-fade-up transition-all duration-700" onMouseOver={showHandler} >
              NFC Products
              {show && <div>
                <ul className="dropdown-nav" onMouseLeave={dontShowHandler} >

                  <BestSellers > </BestSellers>

                </ul>

              </div>}

            </li >


            {/* <Link to="/customize-nfc"> <li className="dropdown nav-link nav-link-fade-up" onMouseOver={showHandler2} >
              CUSTOMIZE NFC
            </li></Link> */}


            {/* <li className="dropdown nav-link nav-link-fade-up" onClick={showHandler3} >
                            View Order
                        </li> */}

            {tabs.map(tab => (
              <li className="dropdown nav-link nav-link-fade-up"
                onClick={() => navigate(`/pages/${tab.name.toLowerCase().replace(/\s+/g, '-')}`, { state: { id: tab.id } })}>
                {tab.name}
              </li>
            ))}

            {/* <li className="dropdown nav-link nav-link-fade-up d-flex justify-content-center align-items-center" onMouseOver={showHandler4} >
              <FontAwesomeIcon icon={faEllipsisVertical} className="secondary-color me-2" />
              {show4 && <div>
                <ul className="dropdown-nav4" onMouseLeave={dontShowHandler} >

                  <MoreMenu > </MoreMenu>

                </ul>

              </div>}
            </li> */}

            <li className="dropdown nav-link nav-link-fade-up" onClick={() => navigate(`/pages/contact-us`)}>
              Contact Us
            </li>
          </ul>
          <div className="ms-auto ps-4 dropdown nav-link nav-link-fade-up d-flex justify-content-center align-items-center" >
            <Link to="/cart">
              <FaShoppingBag className=' text-2xl' />
            </Link>
          </div>


        </nav >
      </header >



    </div >
  )
}

export default NavBar


