import React, { createContext, useContext, useState, useEffect } from 'react';
import Api from '../api/api';
import apiUtil from '../api/apiUtil';

const CompanyContext = createContext();

export const useCompany = () => useContext(CompanyContext);

export const CompanyProvider = ({ children }) => {
  const initialCompanyId = process.env.REACT_APP_COMPANY_ID 
    ? process.env.REACT_APP_COMPANY_ID
    : 1;

  const [companyId, setCompanyId] = useState(initialCompanyId);
  const [companyData, setCompanyData] = useState(null);
  const [isLoadingCompanyData, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        setIsLoading(true);
        const param = {
          company_id: companyId
        };
        
        const getCompanyInfo = await Api.read(`company-info/${companyId}`);
        const [result] = await Promise.all([getCompanyInfo]);

        apiUtil.parseResult(result, (data) => {
          if (data) {
            console.log(data);
            setCompanyData(data.data);
          }
          setIsLoading(false);
        }, (error) => {
          console.error('Error fetching company data:', error);
          setIsLoading(false);
        });
      } catch (error) {
        console.error('API call failed:', error);
        setIsLoading(false);
      }
    };

    fetchCompanyData();
    
  }, []);

  return (
    <CompanyContext.Provider value={{ companyId, companyData, isLoadingCompanyData, setCompanyId }}>
      {children}
    </CompanyContext.Provider>
  );
};
