// src/components/ContactUs.js
import React, { useState, useCallback, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import '../../styles/ContactUs.css'; // Import the CSS file for styling
import Api from "../../api/api"
import apiUtil from '../../api/apiUtil';
import Loading from '../../assets/loading.gif'
import { useCompany } from '../../contexts/CompanyContext';

const ContactUs = () => {
  const [form, setForm] = useState({ name: '', email: '', message: '' });
  const [contactData, setContactData] = useState({ name: '', email: '', message: '' });
  const [loading, setLoading] = useState(false);
  const { companyId, companyData, isLoadingCompanyData } = useCompany();
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };


  useEffect(() => {
    callReadApi();
  }, [companyId])

  const handleSubmit = (e) => {
    e.preventDefault();
    callCreateApi();
  };

  const callReadApi = useCallback(() => {

    let param = {
      company_id: companyId
    };

    setIsLoading(true);
    const getData = Api.read('contact-us', param);

    Promise.all([getData]).then((results) => {
      apiUtil.parseResult(results[0], (data) => {
        const dataArr = data.data ? data.data : {}
        setContactData(dataArr);
        setIsLoading(false);

        console.log('data ds --', dataArr)
      }, (error, type) => {
        setIsLoading(false);
      });
    });
  }, [])

  const callCreateApi = useCallback((callback = null) => {
    setLoading(true);

    Api.create('contact', { ...form, company_id: companyId })
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          setLoading(false);
          setForm({
            name: '',
            email: '',
            description: '',
          });
          if (callback) {
            callback();
          }
        }, (error, type) => {
          setLoading(false);
          setForm({
            name: '',
            email: '',
            description: '',
          });
          if (callback) {
            callback();
          }
        });
      });
  }, [form]);

  return (
    <Container className="contact-us-container my-5">
      <Row className="mb-4">
        <Col>
          <h1 className="text-center">Contact Us</h1>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mb-4">
          <h2>{contactData.title ? contactData.title : 'Get in Touch'}</h2>
          <p>
            {
              contactData.description ? contactData.description
                :
                'We’d love to hear from you! Whether you have questions, need support, or want to explore how SmartStickies can transform your business, our team is here to help.'
            }
          </p>
          <p>
            <strong>Email:</strong> {contactData.email ? contactData.email : ' example@stickies.tech'}<br />
            <strong>phone:</strong> {contactData.phone ? contactData.phone : '+6512345678'}<br />
          </p>
        </Col>
        <Col md={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName" className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={form.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={form.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="formMessage" className="mb-3">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                rows={5}
                value={form.description}
                onChange={handleChange}
                required
              />
            </Form.Group>
            {loading || isLoadingCompanyData ?
              <img src={Loading} className="img-fluid" style={{ objectFit: 'cover', width: '300px', height: '300px' }} />
              :
              <button className='sty-button' style={{
                '--color': companyData?.primary_color ?? '#1366db',
              }} type="submit">
                Send Message
              </button>
            }

          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactUs;
